
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { enableProdMode } from '@angular/core';

if(environment.production){
  Sentry.init({
  dsn: "https://9ae36032bd4d38184148e736ac6eef7d@o392031.ingest.sentry.io/4506759231307776",
  integrations: [
  Sentry.browserTracingIntegration(),
  Sentry.replayIntegration({
  maskAllText: false,
  blockAllMedia: false,
  }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.kobize\.ekowitryna\.pl\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
